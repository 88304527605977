<template>
<div class="container">
    <div class="col-md-8 offset-md-2 col-10 offset-1">
        <div>
            <h1>Kontakt</h1>
            <p>Gisela Debatin</p>
            <p>mooreule@arcor.de</p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Kontakt'
}
</script>

<style scoped>
.container {
    margin-top: 2rem;
}
</style>